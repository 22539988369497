<template>
  <div class="iss-main ta-center">
    <vue-qr :text="link" :size="430"></vue-qr>
    <!--    <a-tabs v-model:activeKey="activeKey" size="large">-->
    <!--      <a-tab-pane key="1" tab="H5">-->
    <!--        <vue-qr :text="link" :size="430"></vue-qr>-->
    <!--      </a-tab-pane>-->
    <!--      <a-tab-pane key="2" tab="小程序" force-render>-->
    <!--        <a-spin :spinning="spinning"> <img :src="qrCodeSrc" alt="" /> </a-spin-->
    <!--      ></a-tab-pane>-->
    <!--    </a-tabs>-->
  </div>
</template>

<script>
// import { Spin, Tabs } from 'ant-design-vue';
import iRadarApi from '@/api/iRadar';
import { nextTick, reactive, toRefs } from 'vue';
import VueQr from 'vue-qr/src';
import { local } from '@/utils/storage';

export default {
  name: 'qrCode',
  components: {
    // ASpin: Spin,
    // ATabs: Tabs,
    // ATabPane: Tabs.TabPane,
    VueQr,
  },
  setup() {
    const link =
      process.env.VUE_APP_I_RADAR_CLIENT_URL +
      `?tenant=${local.get('TENANT')}&timestamp=${new Date().getTime()}`;
    const state = reactive({
      spinning: true,
      activeKey: '1',
      qrCodeSrc: '',
    });
    iRadarApi.getQrCode().then(res => {
      state.qrCodeSrc = res;
      nextTick(() => {
        state.spinning = false;
      });
    });
    return {
      link,
      ...toRefs(state),
    };
  },
};
</script>

<style scoped></style>
